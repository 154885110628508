import { styled } from "styled-components";
import ThreatLevelBlock from "../components/Dashboard/ThreatLevelBlock";
import IssuesBlock from "../components/Dashboard/IssuesBlock";
import useAxios from "../hooks/useAxios";
import axios from "axios";
import { useState } from "react";
import PortsBlock from "../components/Dashboard/PortsBlock";
import ReportList from "../components/Dashboard/ReportList";
import ReportIssuesViewer from "../components/Dashboard/ReportIssuesViewer";
import { DashBoardSchema } from "../types/api";
import { IssueDetail } from "../types/dashboard";
import useDashboard from "../hooks/useDashboard";
import Loading from "../components/common/Loading";
import LoadingSection from "../components/common/LoadingSection";

export default function DashBoardPage() {
  // const { data, loading, error } = useAxios<DashBoardSchema>("/asset/main");
  const { data, loading, error, targetIssues, fetchTargetIssues } =
    useDashboard();

  const onClick = async (id: number) => {
    await fetchTargetIssues(id);
  };
  return (
    <SectionStyle>
      <PageTitleStyle>
        <h1>Dashboard</h1>
      </PageTitleStyle>
      {error && <p>{error.message}</p>}
      {loading ? (
        <LoadingSection />
      ) : (
        <MainSectionStyle>
          {!loading && !error && data && (
            <>
              <ThreatLevelBlock
                threatLevel={(data && data.treatLevel) || "LOW"}
              />
              <IssuesBlock
                issues={
                  (data && data.issues) || {
                    critical: 0,
                    high: 0,
                    medium: 0,
                    low: 0,
                    total: 0,
                  }
                }
              />
              <PortsBlock
                ports={
                  (data && data.ports) || {
                    assetCnt: 0,
                    max: 0,
                    min: 0,
                    mean: 0,
                  }
                }
              />

              <ReportIssuesViewer
                reportIssues={targetIssues || data.issueList}
              />
              <ReportList
                reportList={data && data.reportList}
                onClick={onClick}
                targetId={
                  // (targetReport) ||
                  (targetIssues &&
                    targetIssues.length > 0 &&
                    targetIssues[0].reportId) ||
                  data.reportList[data.reportList.length - 1].id
                }
              />
            </>
          )}
        </MainSectionStyle>
      )}
    </SectionStyle>
  );
}

const LoadingSectionStyle = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
`;
const MainSectionStyle = styled.section`
  width: auto;
  /* height: 100%; */
  /* margin-left: 5rem; */
  padding: 0 5rem;
  padding-bottom: 5rem;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 15rem 1fr;
  grid-gap: 2rem;
  justify-content: start;
  align-content: center;

  & > div:nth-child(1) {
    grid-column: 1 / span 2;
    /* border: solid 4px orange; */
  }
  & > div:nth-child(2) {
    grid-column: 3 / span 2;
    /* border: solid 4px tomato; */
  }
  & > div:nth-child(3) {
    grid-column: 5 / span 2;
    /* border: solid 4px royalblue; */
  }
  & > div:nth-child(4) {
    grid-column: 1 / span 4;
    grid-row: 2;
    /* border: solid 4px yellowgreen; */
  }
  & > div:nth-child(5) {
    grid-column: 5 / span 2;
    grid-row: 2;
    /* border: solid 4px teal; */
  }
`;
const PageTitleStyle = styled.section`
  padding: 1rem;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
`;
const SectionStyle = styled.section`
  width: 100%;
  overflow-y: auto;
`;
// const OverviewStyle = styled.section`
//   margin-left: 5rem;
//   width: 60rem;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
// `;

const SectionOneStyle = styled.section`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
`;

const SectionTwoStyle = styled.section`
  display: flex;
  align-items: center;
`;

const SectionThreeStyle = styled.section`
  display: flex;
  flex-direction: column;
  //width: 40rem;
`;
