import { axiosInstance } from "../api/axios";
import { AxiosResponse } from "axios";
import { ScanResponse } from "../types/api";
import { useQuery } from "@tanstack/react-query";

export default function useScan() {
  const fetchScanList = async () => {
    try {
      const response: AxiosResponse<ScanResponse> = await axiosInstance.get(
        "/asset/scan"
      );
      if (response.status === 200 && response.data.ok) {
        return response.data.data;
      }
    } catch (e: any) {
      throw new Error(e.message);
    }
  };

  return useQuery({
    queryKey: ["scan"],
    queryFn: fetchScanList,
    refetchInterval: 60000,
  });
}
