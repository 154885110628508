import styled from "styled-components";
import { THREAT_LEVELS } from "../../constants/constant";
import MiniScanButton from "./MiniScanButton";
import { AssetDetail } from "../../types/assets";

interface TargetManagerItemProps {
  asset: AssetDetail;
  onClick: any;
}
export default function TargetManagerItem({
  asset,
  onClick,
}: TargetManagerItemProps) {
  const {
    id,
    userId,
    value,
    lastReportId,
    lastReportTime,
    critical,
    high,
    medium,
    low,
    port,
  } = asset;
  const issueFormat = [critical, high, medium, low];

  return (
    <TargetItemStyle>
      {/* <CheckboxStyle type="checkbox" /> */}
      <div></div>
      <NameStyle>{value}</NameStyle>
      <CompoStyle>
        {issueFormat.map((issueCnt, idx) => (
          <CompoItemStyle
            key={THREAT_LEVELS[idx]}
            $threatLevel={THREAT_LEVELS[idx]}
          >
            {issueCnt === null ? "―" : issueCnt}
          </CompoItemStyle>
        ))}
      </CompoStyle>
      <ActivityStyle>{lastReportTime}</ActivityStyle>
      <MiniScanButton onClick={onClick} id={id} />
    </TargetItemStyle>
  );
}

const TargetItemStyle = styled.li`
  display: grid;
  grid-template-columns: 1.5rem 25rem 7rem 18rem 1fr;
  align-items: center;
  padding: ${({ theme }) => theme.padding.lg};
  &:hover {
    background-color: ${({ theme }) => theme.lightTheme.hover};
  }
  font-size: ${({ theme }) => theme.fontSize.base};
  border-bottom: solid 1px ${({ theme }) => theme.lightTheme.border};
`;
const CheckboxStyle = styled.input`
  width: ${({ theme }) => theme.fontSize.md};
  height: ${({ theme }) => theme.fontSize.md};
`;
const NameStyle = styled.div`
  padding: 0 ${({ theme }) => theme.padding.md};
  font-weight: bold;
`;

const CompoStyle = styled.div`
  display: grid;
  grid-auto-flow: column;
`;

const CompoItemStyle = styled.div<{ $threatLevel: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme, $threatLevel }) =>
    theme.threatLevelLite[$threatLevel]};
  color: ${({ theme, $threatLevel }) => theme.threatLevel[$threatLevel]};
  border-radius: ${({ theme }) => theme.rounded.xs};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: 600;
`;

const ActivityStyle = styled.div`
  padding: 0 ${({ theme }) => theme.padding.base};
`;
