import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

export const axiosInstance = axios.create({
  baseURL: "https://api.mi-kuro.com",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("mikuro_token");

    try {
      if (accessToken)
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      return config;
    } catch (e: any) {
      console.error("[_axios.interceptors.request] config : " + e.message);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        default:
          alert("Session expired!");
          window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);
// 미사용
export const loginAxiosInstance = axios.create({
  baseURL: "https://api.mi-kuro.com",
});

loginAxiosInstance.interceptors.response.use();
