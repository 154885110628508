import React from "react";
import styled from "styled-components";
function NotfoundPage() {
  return <MainSectionStyle>404 Page Not Found</MainSectionStyle>;
}

export default NotfoundPage;

const MainSectionStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.lightTheme.sideBar};
  color: white;

  font-size: 5rem;
`;
