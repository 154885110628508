import styled from "styled-components";

type BlockProps = {
  width?: string;
  color?: string;
  bgcolor?: string;
  onClick?: any;
  children: React.ReactNode;
};

export default function DashboardBlock({
  width = "100%",
  color = "black",
  bgcolor = "white",
  onClick = () => {},
  children,
}: BlockProps) {
  return (
    <BlockStyle
      $width={width}
      $bgcolor={bgcolor}
      $color={color}
      onClick={onClick}
    >
      {children}
    </BlockStyle>
  );
}

const BlockStyle = styled.div<{
  $width?: string;
  $bgcolor: string;
  $color: string;
}>`
  width: ${(props) => props.$width};
  min-width: 137px;
  height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1rem;

  background-color: ${(props) => props.$bgcolor};
  border: solid 2px ${({ theme }) => theme.lightTheme.border};
  border-radius: 15px;
  color: ${(props) => props.$color};
  font-size: ${({ theme }) => theme.fontSize.md};

  //box-shadow: 0 0 3px 3px ${({ theme }) => theme.lightTheme.shadow};
`;
