import React from "react";
import styled from "styled-components";
import { IssueDetail } from "../../types/dashboard";

interface ReportIssueListProps {
  issueList: IssueDetail[];
}
function ReportIssuesList({ issueList }: ReportIssueListProps) {
  return (
    <BodyStyle>
      <ListStyle>
        {issueList.map((issue, idx) => {
          const { description, title, severity, remediation } = issue;
          return (
            <ItemStyle key={`${title}_${idx}`}>
              <AccordionHead>
                <IssueNameStyle>{title}</IssueNameStyle>
                <IssueLevelStyle $threatLevel={severity}>
                  {severity}
                </IssueLevelStyle>
              </AccordionHead>
              <AccordionBody>
                <DescriptionStyle>Descrpition: {description}</DescriptionStyle>
                {remediation !== "None" && (
                  <DescriptionStyle>
                    Remediation: {remediation}
                  </DescriptionStyle>
                )}
              </AccordionBody>
            </ItemStyle>
          );
        })}
      </ListStyle>
    </BodyStyle>
  );
}

export default ReportIssuesList;

const TabContainerStyle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
`;
const TabStyle = styled.div<{
  $highlight: boolean;
}>`
  background-color: ${({ $highlight, theme }) =>
    $highlight ? theme.threatLevelLite.info : "none"};
  border-radius: ${({ theme }) => theme.rounded.sm};
  padding: ${({ theme }) => theme.padding.sm};
`;

const DescriptionStyle = styled.div`
  min-height: 2rem;
  padding: 0.5rem 0;
  // 글 간격 조정
  line-height: 1.5rem;
`;
const IssueNameStyle = styled.div`
  font-weight: bold;
`;
const IssueLevelStyle = styled.div<{ $threatLevel: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-left: 0.5rem;
  color: ${({ theme, $threatLevel }) => theme.threatLevel[$threatLevel]};
  background-color: ${({ theme, $threatLevel }) =>
    theme.threatLevelLite[$threatLevel]};
  font-weight: 500;
  border-radius: ${({ theme }) => theme.rounded.sm};
`;
const HeaderStyle = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  padding: ${({ theme }) => theme.padding.base};
  border-bottom: solid 1px ${({ theme }) => theme.lightTheme.border};
`;
const HeaderTitleStyle = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.base};
  padding-bottom: 0.5rem;
`;
const BoldStyle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
`;

const BodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.lightTheme.secondary};
  //overflow-y: auto;
`;

const ListStyle = styled.ol`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  /* width: 40rem; */
`;
const ItemStyle = styled.li`
  padding: ${({ theme }) => theme.padding.xs};
  list-style: none;
  /* padding: ${({ theme }) => theme.padding.sm}; */
  border-radius: ${({ theme }) => theme.rounded.sm};
  border: 1px solid ${({ theme }) => theme.lightTheme.border};
  background-color: ${({ theme }) => theme.lightTheme.primary};
`;

const AccordionHead = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-bottom: solid 1px ${({ theme }) => theme.lightTheme.border};
  /* border-radius: ${({ theme }) => theme.rounded.sm}; */
`;

const AccordionBody = styled.div`
  border-bottom-right-radius: ${({ theme }) => theme.rounded.sm};
  border-bottom-left-radius: ${({ theme }) => theme.rounded.sm};
`;
