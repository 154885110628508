import React from "react";
import { ContainerStyle } from "../common/DefaultContainer";
import styled from "styled-components";
import { ScanSchema } from "../../types/scan";
import ScanManagerItem from "./ScanManagerItem";

type ScanManagerProps = {
  scans: ScanSchema;
};
export default function ScanManager({ scans }: ScanManagerProps) {
  return (
    <ContainerStyle>
      <ScanHeaderStyle>Scan Status</ScanHeaderStyle>
      <ScanBodyStyle>
        <ScanListStyle>
          {scans.length ? (
            scans.map((scan) => <ScanManagerItem scan={scan} />)
          ) : (
            <NoScanStyle>No scans are in progress</NoScanStyle>
          )}
        </ScanListStyle>
      </ScanBodyStyle>
    </ContainerStyle>
  );
}

export const NoScanStyle = styled.div`
  height: 100px;
  background-color: white;
  text-align: center;
  line-height: 100px;
  font-weight: 500;
  font-size: 1.1rem;
  background-color: ${({ theme }) => theme.lightTheme.hover};
`;
const ScanHeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.padding.base};
  border-bottom: solid 1px ${({ theme }) => theme.lightTheme.border};
  font-size: 1.2rem;
  font-weight: 500;
`;

const ScanBodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow-y: auto;
`;

const ScanListStyle = styled.ol`
  width: 100%;
  overflow-y: auto;
`;
