import { AxiosError, AxiosResponse } from "axios";
import React, { useState } from "react";
import { axiosInstance } from "../api/axios";
import { AssetScanResponse } from "../types/api";

export default function useAssetScan() {
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(true);
  const handleAssetScan = async (id: number) => {
    try {
      const response: AxiosResponse<AssetScanResponse> =
        await axiosInstance.post(`/asset/scan/${id}`);
      if (response.status === 200 && response.data.ok) {
        if (response.data.message === "asset scan success") {
          alert("asset scan started");
        } else {
          alert("failed to start asset scan");
        }
      }
    } catch (e: any) {
      setError(e);
    }
    setLoading(false);
  };

  return { handleAssetScan };
}
