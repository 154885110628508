import React, { useRef } from "react";
import { ReportsDetail } from "../../types/api";
import styled from "styled-components";
import { THREAT_LEVELS } from "../../constants/constant";
import ReportIssuesViewer from "../Dashboard/ReportIssuesViewer";
import ReportIssuesList from "./ReportIssuesList";
// import "../../styles/selectedReportViewer.css";
type SelectedReportViewerProps = {
  report: ReportsDetail;
};
function SelectedReportViewer(
  { report }: SelectedReportViewerProps,
  ref: React.Ref<HTMLElement>
) {
  const {
    id,
    time,
    issueList,
    finished,
    assetValue,
    critical,
    high,
    medium,
    low,
    port,
    assetId,
  } = report;
  const formattedIssues = [critical, high, medium, low];
  const highestIssueLevel: string =
    THREAT_LEVELS[formattedIssues.findIndex((val, idx) => val) || 3];
  return (
    <SectionStyle ref={ref}>
      <div>
        <HeaderStyle>
          <TitleBoldStyle>Report for {assetValue}</TitleBoldStyle>
        </HeaderStyle>
        <BodyStyle>
          <ScanInfoStyle>
            <CommonHeaderStyle>Scan Details</CommonHeaderStyle>
            <ScanBody>
              {time}
              <ScanStatusStyle $finished={finished}>
                {finished ? "Done" : "Scanning"}
              </ScanStatusStyle>
            </ScanBody>
          </ScanInfoStyle>
          <AssetStyle>
            <CommonHeaderStyle>Asset Details</CommonHeaderStyle>
            <div>Asset : {assetValue}</div>
          </AssetStyle>
          <CommonHeaderStyle>Issue Summary</CommonHeaderStyle>
          <WrapperStyle>
            <IssueContainerStyle $highestIssueLevel={highestIssueLevel}>
              {formattedIssues.map((issueCnt, idx) => (
                <IssueStyle
                  $issueLevel={THREAT_LEVELS[idx]}
                  key={THREAT_LEVELS[idx]}
                >
                  {issueCnt}
                </IssueStyle>
              ))}
            </IssueContainerStyle>
          </WrapperStyle>

          <ReportIssuesList issueList={issueList} />
          <CommonHeaderStyle>Port Details</CommonHeaderStyle>
          <div>
            <BoldStyle>{port}</BoldStyle> ports
          </div>
          {report.portList && (
            <div>
              {report.portList.map((port, idx) => (
                <PortItemStyle key={port.id}>
                  <div>
                    port# <SemiBoldStyle>{port.portNum}</SemiBoldStyle>
                  </div>
                  <div>
                    protocol <SemiBoldStyle>{port.protocol}</SemiBoldStyle>
                  </div>
                  <div>
                    version <SemiBoldStyle>{port.version}</SemiBoldStyle>
                  </div>
                </PortItemStyle>
              ))}
            </div>
          )}
        </BodyStyle>
      </div>
    </SectionStyle>
  );
}

export default React.forwardRef(SelectedReportViewer);

const SectionStyle = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: auto;
`;
const HeaderStyle = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  text-decoration: underline;
`;
const WrapperStyle = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const HeaderTitleStyle = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.base};
  padding-bottom: 0.5rem;
`;
const TitleBoldStyle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
  margin-left: 0.5rem;
`;
const BodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40rem;
  /* box-shadow: 3px 3px 3px 3px lightgray; */
`;
const CommonHeaderStyle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: bold;
  text-decoration: underline;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;
const ScanInfoStyle = styled.div``;
const ScanBody = styled.div`
  display: flex;
  align-items: center;

  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: 500;
`;
const ScanStatusStyle = styled.div<{ $finished: boolean }>`
  width: fit-content;
  height: fit-content;

  padding: 0.25rem;
  margin-left: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: bold;
  background-color: ${({ theme, $finished }) =>
    $finished
      ? theme.threatLevelLite["medium"]
      : theme.threatLevelLite["high"]};
  border-radius: ${({ theme }) => theme.rounded.xs};
`;

const AssetStyle = styled.div``;
const AssetHeader = styled.div``;
const IssueContainerStyle = styled.div<{ $highestIssueLevel: string }>`
  width: 35rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  border: solid 8px
    ${({ theme, $highestIssueLevel }) => theme.threatLevel[$highestIssueLevel]};
`;

const IssueStyle = styled.div<{ $issueLevel: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  background-color: ${({ theme, $issueLevel }) =>
    theme.threatLevelLite[$issueLevel]};
  color: ${({ theme, $issueLevel }) => theme.threatLevel[$issueLevel]};
  border-radius: ${({ theme }) => theme.rounded.xs};
  // font-size: ${({ theme }) => theme.fontSize.base};
  font-size: 2rem;
  font-weight: 600;
`;

const PortItemStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 5rem;

  margin-bottom: 1rem;
  padding-left: 1rem;
  border-left: 7px solid ${({ theme }) => theme.lightTheme.focus};
`;

const BoldStyle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: bold;
  margin-right: 0.5rem;
`;
const SemiBoldStyle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: bold;
  margin-right: 0.5rem;
`;
