import styled from "styled-components";
import LoginButton from "../components/Login/LoginButton";
import ClickableText from "../components/common/ClickableText";
import useLogin from "../hooks/useLogin";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
export default function LoginPage() {
  const { loading, error, postData } = useLogin();
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({ email: "", password: "" });

  const handleLogin = () => {
    if (!loginData || !loginData.email || !loginData.password) {
      alert("Please enter both email and password for login");
      return;
    }
    postData(loginData.email, loginData.password);
  };
  return (
    <MainSectionStyle>
      <InputContainerStyle>
        <InputStyle
          type="email"
          placeholder="email"
          value={loginData.email}
          onChange={(e) =>
            setLoginData((cur) => ({ ...cur, email: e.target.value }))
          }
        />
        <InputStyle
          type="password"
          placeholder="password"
          value={loginData.password}
          onChange={(e) =>
            setLoginData((cur) => ({ ...cur, password: e.target.value }))
          }
        />
        <LoginButton onClick={handleLogin} isLoading={loading} />
        {/* <LoginStyle>Log in</LoginStyle> */}
      </InputContainerStyle>

      {/* <ClickableText
        onClick={() => navigate("/password/new")}
        textContent="Forgot your password?"
      /> */}
      <ClickableText
        onClick={() => navigate("/signup")}
        textContent="Sign up"
      />
    </MainSectionStyle>
  );
}

const MainSectionStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.lightTheme.sideBar};
`;

const InputContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 12rem;
`;
const InputStyle = styled.input`
  height: 1.5rem;
  width: 20rem;
  line-height: 2rem;
  padding: ${({ theme }) => theme.padding.xs};
  border-radius: ${({ theme }) => theme.rounded.sm};
  background-color: ${({ theme }) => theme.lightTheme.hover};
  margin-bottom: 1rem;
  outline: none;

  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: bold;
  &:focus {
    box-shadow: inset 0 0 0 4px ${({ theme }) => theme.lightTheme.focus};
  }
`;
