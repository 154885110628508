import React, { createRef, useRef } from "react";
import SelectedReportViewer from "../components/Reports/SelectedReportViewer";
import { ReportsDetail } from "../types/api";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import PrintButton from "../components/Reports/PrintButton";

function SelectedReportPage() {
  const location = useLocation();
  const ref = createRef<HTMLDivElement>();

  const { state }: { state: ReportsDetail } = location;
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });
  return (
    <SectionStyle>
      <MainSectionStyle>
        <PrintButton onClick={handlePrint} />
        <SelectedReportViewer report={state || {}} ref={ref} />
      </MainSectionStyle>
    </SectionStyle>
  );
}

export default SelectedReportPage;

const MainSectionStyle = styled.section`
  width: 60rem;
  height: 100%;
  padding-bottom: 5rem;
  margin-left: 5rem;
  /* overflow-y: auto; */
`;
const PageTitleStyle = styled.section`
  padding: 1rem;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
`;
const SectionStyle = styled.section`
  width: 100%;
  overflow-y: auto;
`;
