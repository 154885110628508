import styled from "styled-components";

export const ContainerStyle = styled.div`
  width: auto;
  /* height: max-content; */
  display: grid;
  grid-template-rows: 4rem 1fr;
  border: solid 1px ${({ theme }) => theme.lightTheme.border};
  border-radius: ${({ theme }) => theme.rounded.lg};
  overflow: clip;
`;
