import React from "react";

type CheckProps = {
  width?: string;
  stroke?: string;
  color?: string;
};
function Check({
  width = "2rem",
  stroke = "1.5",
  color = "#0FDD72",
}: CheckProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={stroke}
      stroke={color}
      className="w-6 h-6"
      width={width}
      height={width}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

export default Check;
