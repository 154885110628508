import { styled } from "styled-components";
import useReports from "../hooks/useReports";
import ReportsList from "../components/Reports/ReportsList";
import SelectedReportViewer from "../components/Reports/SelectedReportViewer";
import { useNavigate } from "react-router-dom";
import LoadingSection from "../components/common/LoadingSection";

export default function ReportsPage() {
  const navigate = useNavigate();
  const { data, loading, error, fetchSelectedReport, selectedReport } =
    useReports();
  const handleReportItemClick = async (id: number) => {
    await fetchSelectedReport(id);
    //navigate(`/reports/${id}`, { state: { ...selectedReport } });
  };
  return (
    <SectionStyle>
      <PageTitleStyle>
        <h1>Reports</h1>
      </PageTitleStyle>
      {loading ? (
        <LoadingSection />
      ) : (
        <MainSectionStyle>
          {error && <p>{error.message}</p>}
          {!loading && !error && data && (
            <section>
              <ReportsList
                reports={data}
                selectedReport={selectedReport}
                onClick={handleReportItemClick}
              />
            </section>
          )}
        </MainSectionStyle>
      )}

      {/* {selectedReport.id !== -1 && (
        <SelectedReportViewer report={selectedReport} />
      )} */}
    </SectionStyle>
  );
}

const MainSectionStyle = styled.section`
  width: 60rem;
  height: 100%;
  padding-bottom: 5rem;
  margin-left: 5rem;
  overflow-y: auto;
`;
const PageTitleStyle = styled.section`
  padding: 1rem;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
`;
const SectionStyle = styled.section`
  width: 100%;
  overflow-y: auto;
`;
