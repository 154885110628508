import TargetManager from "../components/Targets/TargetManager";
import styled from "styled-components";
import useAxios from "../hooks/useAxios";
import useAssets from "../hooks/useAssets";
import AssetAddButton from "../components/Targets/AssetAddButton";
import LoadingSection from "../components/common/LoadingSection";
import useAssetScan from "../hooks/useAssetScan";

export default function TargetsPage() {
  const { data, loading, error, fetchAddAsset } = useAssets();
  const handleAddAsset = () => {
    const value = window.prompt("enter your asset");
    if (value) fetchAddAsset(value);
  };
  const { handleAssetScan } = useAssetScan();
  return (
    <SectionStyle>
      <PageTitleStyle>
        <h1>Assets</h1>
      </PageTitleStyle>
      {loading ? (
        <LoadingSection />
      ) : (
        <MainSectionStyle>
          <AssetAddButton onClick={handleAddAsset} />
          <TargetManager
            assets={
              data || [
                {
                  id: -1,
                  userId: -1,
                  value: "",
                  lastReportId: -1,
                  lastReportTime: "",
                  critical: 0,
                  high: 0,
                  medium: 0,
                  low: 0,
                  port: 0,
                },
              ]
            }
            handleAssetScan={handleAssetScan}
          />
        </MainSectionStyle>
      )}
    </SectionStyle>
  );
}

const SectionStyle = styled.section`
  width: 100%;
  overflow-y: auto;
`;
const PageTitleStyle = styled.section`
  padding: 1rem;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
`;
const MainSectionStyle = styled.section`
  width: 60rem;
  height: 100%;
  margin-left: 5rem;
`;
