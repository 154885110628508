import { useState } from "react";
import { ContainerStyle } from "../common/DefaultContainer";
import styled from "styled-components";
import { IssueDetail } from "../../types/dashboard";

export type ReportIssuesViewerProps = {
  reportIssues: IssueDetail[];
};

export default function ReportIssuesViewer({
  reportIssues,
}: ReportIssuesViewerProps) {
  const id = reportIssues[0].id;
  const [currentIssue, setCurrentIssue] = useState("");
  const [issueBodyType, setIssueBodyType] = useState("description");
  return (
    <ContainerStyle>
      <HeaderStyle>
        <HeaderTitleStyle>Report</HeaderTitleStyle>
        <BoldStyle>#{id}</BoldStyle>
      </HeaderStyle>
      <BodyStyle>
        <ListStyle>
          {reportIssues.map((issue, idx) => {
            const { description, title, severity, remediation } = issue;
            return (
              <ItemStyle key={`${title}_${idx}`}>
                <AccordionHead
                  onClick={() => {
                    if (currentIssue === `${title}_${idx}`) setCurrentIssue("");
                    else setCurrentIssue(`${title}_${idx}`);
                  }}
                >
                  <IssueNameStyle>{title}</IssueNameStyle>
                  <IssueLevelStyle $threatLevel={severity}>
                    {severity}
                  </IssueLevelStyle>
                </AccordionHead>
                {currentIssue === `${title}_${idx}` && (
                  <AccordionBody>
                    <TabContainerStyle>
                      <TabStyle
                        onClick={() =>
                          setIssueBodyType((prev) =>
                            prev === "description"
                              ? "remediation"
                              : "description"
                          )
                        }
                        $highlight={issueBodyType === "description"}
                      >
                        Description
                      </TabStyle>
                      <TabStyle
                        onClick={() =>
                          setIssueBodyType((prev) =>
                            prev === "description"
                              ? "remediation"
                              : "description"
                          )
                        }
                        $highlight={issueBodyType === "remediation"}
                      >
                        Remediation
                      </TabStyle>
                    </TabContainerStyle>

                    {issueBodyType === "description" && (
                      <DescriptionStyle>{description}</DescriptionStyle>
                    )}
                    {issueBodyType === "remediation" && (
                      <DescriptionStyle>
                        {remediation || "No clear remediations were found!"}
                      </DescriptionStyle>
                    )}
                  </AccordionBody>
                )}
              </ItemStyle>
            );
          })}
        </ListStyle>
      </BodyStyle>
    </ContainerStyle>
  );
}

const TabContainerStyle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
`;
const TabStyle = styled.div<{
  $highlight: boolean;
}>`
  background-color: ${({ $highlight, theme }) =>
    $highlight ? theme.threatLevelLite.info : "none"};
  border-radius: ${({ theme }) => theme.rounded.sm};
  padding: ${({ theme }) => theme.padding.sm};
`;

const DescriptionStyle = styled.div`
  min-height: 2rem;
`;
const IssueNameStyle = styled.div`
  font-weight: bold;
`;
const IssueLevelStyle = styled.div<{ $threatLevel: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-left: 0.5rem;
  color: ${({ theme, $threatLevel }) => theme.threatLevel[$threatLevel]};
  background-color: ${({ theme, $threatLevel }) =>
    theme.threatLevelLite[$threatLevel]};
  font-weight: 500;
  border-radius: ${({ theme }) => theme.rounded.sm};
`;
const HeaderStyle = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  padding: ${({ theme }) => theme.padding.base};
  border-bottom: solid 1px ${({ theme }) => theme.lightTheme.border};
`;
const HeaderTitleStyle = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.base};
  padding-bottom: 0.5rem;
`;
const BoldStyle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
`;

const BodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  //width: 100%;
  height: 30rem;
  background-color: ${({ theme }) => theme.lightTheme.secondary};
  overflow-y: auto;
`;

const ListStyle = styled.ol`
  padding: 1rem;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
`;
const ItemStyle = styled.li`
  padding: ${({ theme }) => theme.padding.xs};
  list-style: none;
  border-radius: ${({ theme }) => theme.rounded.sm};
  border: 1px solid ${({ theme }) => theme.lightTheme.border};
  background-color: ${({ theme }) => theme.lightTheme.primary};
  margin-bottom: 1rem;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`;

const AccordionHead = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-bottom: solid 1px ${({ theme }) => theme.lightTheme.border};
  /* border-radius: ${({ theme }) => theme.rounded.sm}; */
`;

const AccordionBody = styled.div`
  border-bottom-right-radius: ${({ theme }) => theme.rounded.sm};
  border-bottom-left-radius: ${({ theme }) => theme.rounded.sm};
`;
