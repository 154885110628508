import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const SIDEBAR_NAV = Object.freeze([
  "dashboard",
  "assets",
  "scans",
  // "issues",
  "reports",
]);

export default function Sidebar() {
  const { pathname } = useLocation();
  const currentPage = pathname.split("/")[1];
  const navigate = useNavigate();
  return (
    <SidebarStyle>
      {SIDEBAR_NAV.map((item) => {
        const title = item.charAt(0).toUpperCase() + item.slice(1);
        return (
          <SidebarItemStyle
            key={item}
            $onPage={currentPage === item}
            onClick={() => {
              if (currentPage === item) return;
              navigate(`/${item}`);
            }}
          >
            {title}
          </SidebarItemStyle>
        );
      })}
      <SidebarItemStyle
        $onPage={false}
        onClick={() => {
          if (window.confirm("log out ?")) {
            localStorage.removeItem("mikuro_token");
            navigate("/login");
          }
        }}
      >
        Logout
      </SidebarItemStyle>
    </SidebarStyle>
  );
}

const SidebarStyle = styled.ul`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: repeat(10, 1fr);
  /* min-width: 13rem; */
  height: 100%;
  background-color: ${({ theme }) => theme.lightTheme.sideBar};
  color: ${({ theme }) => theme.lightTheme.fontWhite};
  font-weight: bold;
`;

const SidebarItemStyle = styled.li<{ $onPage: boolean }>`
  display: flex;
  align-items: center;

  padding: ${({ theme }) => theme.padding.base};
  font-size: ${({ theme }) => theme.fontSize.md};

  border-right: ${({ $onPage, theme }) =>
    $onPage ? `solid 0.35rem ${theme.lightTheme.focus}` : "none"};
  box-shadow: ${({ $onPage, theme }) =>
    $onPage ? `inset -0.75rem ${theme.lightTheme.focus}` : "none"};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.lightTheme.sieBarHover};
  }
`;
