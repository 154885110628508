import styled from "styled-components";
import ScanManager from "../components/Scans/ScanManager";
import useScan from "./../hooks/useScan";
import LoadingSection from "../components/common/LoadingSection";

export default function ScanPage() {
  const { data, isFetching, error, status } = useScan();

  return (
    <SectionStyle>
      <PageTitleStyle>Scan</PageTitleStyle>
      {status === "pending" ? (
        <LoadingSection />
      ) : (
        <MainSectionStyle>
          {/* <ScanButton /> */}

          <ScanManager scans={data || []} />
        </MainSectionStyle>
      )}
      {/* make list with simple list ui css */}
    </SectionStyle>
  );
}

const SectionStyle = styled.section`
  width: 100%;
  overflow-y: auto;
`;
const PageTitleStyle = styled.section`
  padding: 1rem;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
`;
const MainSectionStyle = styled.section`
  width: 60rem;
  height: 100%;
  margin-left: 5rem;
`;
