const windowSize = {
  sm: 'screen and (max-width: "600px")',
  md: 'screen and (max-width: "768px")',
  lg: 'screen and (max-width: "1024px")',
};

const fontSize = {
  xs: "0.5rem",
  sm: "0.75rem",
  base: "1rem",
  md: "1.25rem",
  lg: "1.5rem",
};

const padding = {
  xs: "0.5rem",
  sm: "0.75rem",
  base: "1rem",
  md: "1.25rem",
  lg: "1.5rem",
};
const rounded = {
  xs: "5px",
  sm: "7px",
  lg: "15px",
};
const threatLevel = {
  critical: "#ED5463",
  high: "#E4AE56",
  medium: "#19B293",
  low: "#0084C1",
  info: "#0084C1",
};

const threatLevelLite = {
  critical: "#FFE4E9",
  high: "#FFE5D0",
  medium: "#D7F7F1",
  low: "#D1F1FD",
  info: "#D1F1FD",
};

const lightTheme = {
  background: "#fff",
  fontPrimary: "black",
  fontSecondary: "gray",
  fontWhite: "white",
  primary: "white",
  secondary: "#F2F2F2",
  sideBar: "#0F3443",
  sieBarHover: "#0C2A36",
  focus: "#FEC400",
  hover: "#edf2fb",
  border: "lightgray",
  shadow: "#F2F2F2",
};

const theme = {
  windowSize,
  fontSize,
  padding,
  threatLevel,
  threatLevelLite,
  lightTheme,
  rounded,
};

export default theme;
