import { ThemeContext } from "styled-components";
import { useContext } from "react";
import DashboardBlock from "../common/DashboardBlock";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ThreatLevel } from "../../types/dashboard";
import { THREAT_ABBREV } from "../../constants/constant";

type ThreatLevelBlockProps = {
  threatLevel: ThreatLevel;
};
export default function ThreatLevelBlock({
  threatLevel = "LOW",
}: ThreatLevelBlockProps) {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const formattedThreat = THREAT_ABBREV[threatLevel];
  const title =
    formattedThreat.charAt(0).toUpperCase() + formattedThreat.slice(1);
  return (
    <DashboardBlock
      width="auto"
      bgcolor={theme && theme.threatLevel[formattedThreat]}
      color={theme && theme.lightTheme.fontWhite}
    >
      <div>
        <TitleStyle>Threat Level</TitleStyle>
        <ThreatStyle>{title}</ThreatStyle>
      </div>
    </DashboardBlock>
  );
}

const TitleStyle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: 500;
  text-align: center;
`;
const ThreatStyle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
  padding: 0.5rem;
  text-align: center;
`;
