import styled, { keyframes } from "styled-components";

type LoadingProps = {
  width?: string;
  thickness?: string;
};
export default function Loading({
  width = "1rem",
  thickness = "4px",
}: LoadingProps) {
  return <SpinnerStyle $width={width} $thickness={thickness}></SpinnerStyle>;
}

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const SpinnerStyle = styled.div<{ $width: string; $thickness: string }>`
  border: ${({ $thickness }) => $thickness} solid #f3f3f3; /* Light grey */
  border-top: ${({ $thickness }) => $thickness} solid
    ${({ theme }) => theme.threatLevel.medium}; /* Blue */
  border-radius: 50%;
  width: ${({ $width }) => $width};
  height: ${({ $width }) => $width};
  animation: ${rotate} 1.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
`;
