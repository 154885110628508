import DashboardBlock from "../common/DashboardBlock";
import styled from "styled-components";
import { THREAT_LEVELS } from "../../constants/constant";

type IssuesBlockProps = {
  issues?: {
    critical: number;
    high: number;
    medium: number;
    low: number;
    total: number;
  };
};
const DEFAULT_PROP = {
  critical: 0,
  high: 0,
  medium: 0,
  low: 0,
  total: 0,
};
export default function IssuesBlock({
  issues = DEFAULT_PROP,
}: IssuesBlockProps) {
  const totalCnt = issues.total;
  const { critical, high, medium, low } = issues;
  const issueCntArray = [critical, high, medium, low];
  return (
    <DashboardBlock width="auto">
      <IssueHeaderStyle>
        <HeaderTitleStyle>Issues</HeaderTitleStyle>
        <HeaderCntStyle>{totalCnt}</HeaderCntStyle>
      </IssueHeaderStyle>
      <IssueCompositionStyle>
        {issueCntArray.map((issueCnt, idx) => {
          const threatLvl = THREAT_LEVELS[idx];
          const title = threatLvl.charAt(0).toUpperCase() + threatLvl.slice(1);
          return (
            <IssueTypeStyle
              key={threatLvl}
              // onClick={() => navigate(`/issues?threat=${threatLvl}`)}
              $threatLevel={THREAT_LEVELS[idx]}
            >
              <IssueTypeTitleStyle>{title}</IssueTypeTitleStyle>
              <IssueTypeCntStyle>{issueCnt}</IssueTypeCntStyle>
            </IssueTypeStyle>
          );
        })}
      </IssueCompositionStyle>
    </DashboardBlock>
  );
}

const HeaderTitleStyle = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.base};
  padding-bottom: 0.5rem;
`;
const HeaderCntStyle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
`;
const IssueHeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 7px;

  &:hover {
    box-shadow: 0 0 3px 3px ${({ theme }) => theme.lightTheme.shadow};
  }
`;
const IssueCompositionStyle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 5px;
`;

const IssueTypeStyle = styled.div<{ $threatLevel: string }>`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background-color: ${({ theme, $threatLevel }) =>
    theme.threatLevelLite[$threatLevel]};
  color: ${({ theme, $threatLevel }) => theme.threatLevel[$threatLevel]};

  border-radius: 7px;
  font-size: ${({ theme }) => theme.fontSize.base};
  &:hover {
    box-shadow: 0 0 3px 3px;
  }
`;

const IssueTypeTitleStyle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 600;
  padding-bottom: 0.5rem;
`;
const IssueTypeCntStyle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: bold;
`;
