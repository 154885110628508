import React from "react";
import styled from "styled-components";
import MainRouter from "./router/router";

function App() {
  return (
    <RootStyle>
      <MainRouter />
    </RootStyle>
  );
}

export default App;

const RootStyle = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
`;
