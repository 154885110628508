export const THREAT_LEVELS = Object.freeze([
  "critical",
  "high",
  "medium",
  "low",
]);

export const THREAT_ABBREV = Object.freeze({
  CRIT: "critical",
  HIGH: "high",
  MED: "medium",
  LOW: "low",
});
