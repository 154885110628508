import styled from "styled-components";
import { ContainerStyle } from "../common/DefaultContainer";
import { ReportDetail } from "../../types/dashboard";

type ReportListProps = {
  reportList: ReportDetail[];
  onClick: any;
  targetId: number;
};
export default function ReportList({
  reportList,
  onClick,
  targetId,
}: ReportListProps) {
  return (
    <ContainerStyle>
      <ListHeader>Recent Reports</ListHeader>
      <ListStyle>
        {reportList &&
          reportList.map(({ assetValue, time, id }) => (
            <ReportItemStyle
              key={id}
              onClick={() => onClick(id)}
              $focus={id === targetId}
            >
              <div>{assetValue}</div>
              <div>{time}</div>
            </ReportItemStyle>
          ))}
      </ListStyle>
    </ContainerStyle>
  );
}

const ListHeader = styled.div`
  padding: 1rem;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.base};
`;
const ListStyle = styled.ul`
  display: inline-block;
  padding: ${({ theme }) => theme.padding.sm};
  background-color: ${({ theme }) => theme.lightTheme.secondary};
  border-radius: ${({ theme }) => theme.rounded.sm};
`;

const ReportItemStyle = styled.li<{ $focus: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  /* width: 100%; */
  padding: 1rem;
  border-radius: ${({ theme }) => theme.rounded.sm};
  background-color: ${({ theme }) => theme.lightTheme.primary};
  cursor: pointer;
  box-shadow: ${({ $focus, theme }) =>
    $focus ? `inset -5px 0 ${theme.lightTheme.focus}` : "none"};
  &:hover {
    box-shadow: inset -5px 0 ${({ theme }) => theme.lightTheme.focus};
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
