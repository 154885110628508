import { Outlet } from "react-router-dom";
import Sidebar from "../components/common/Sidebar";
import styled from "styled-components";

export function SidebarLayout() {
  return (
    <SectionStyle>
      <Sidebar />
      <Outlet />
    </SectionStyle>
  );
}

const SectionStyle = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 13rem 1fr;
  justify-content: center;
  box-sizing: border-box;
`;
