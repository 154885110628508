import React, { useEffect, useState } from "react";
import { axiosInstance } from "../api/axios";
import { AxiosError, AxiosResponse } from "axios";
import {
  ReportsDetail,
  ReportsResponse,
  ReportsSchema,
  SelectedReportResponse,
} from "../types/api";
import { ReportDetail } from "../types/dashboard";
import { useNavigate } from "react-router-dom";

const dummy = {
  id: -1,
  time: "",
  issueList: [],
  finished: true,
  assetValue: "",
  critical: 0,
  high: 0,
  medium: 0,
  low: 0,
  port: 0,
  assetId: 0,
};
function useReports() {
  const navigate = useNavigate();
  const [data, setData] = useState<ReportsSchema>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState<ReportsDetail>(dummy);
  const fetchData = async () => {
    try {
      const response: AxiosResponse<ReportsResponse> = await axiosInstance.get(
        "/asset/report"
      );
      if (response.status === 200 && response.data.ok) {
        setData(response.data.data);
      }
    } catch (e: any) {
      setError(e);
    }

    setLoading(false);
  };

  const fetchSelectedReport = async (id: number) => {
    try {
      const response: AxiosResponse<SelectedReportResponse> =
        await axiosInstance.get(`/asset/report/${id}`);
      if (response.status === 200 && response.data.ok) {
        setSelectedReport(response.data.data);
        navigate(`/reports/${id}`, { state: { ...response.data.data } });
      }
    } catch (e: any) {
      setError(e);
    }

    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return { data, error, loading, fetchSelectedReport, selectedReport };
}

export default useReports;
