import React from "react";
import DefaultButton from "../common/DefaultButton";

type LoginButtonProps = {
  onClick: any;
  isLoading: boolean;
};
function LoginButton({
  onClick = () => {},
  isLoading = false,
}: LoginButtonProps) {
  return (
    <DefaultButton
      width="100%"
      height="2.7rem"
      onClick={onClick}
      isLoading={isLoading}
    >
      Log in
    </DefaultButton>
  );
}

export default LoginButton;
