import React from "react";
import styled from "styled-components";

type ClickableTextProps = {
  onClick: any;
  textContent: string;
};
function ClickableText({ onClick, textContent }: ClickableTextProps) {
  return <TextStyle onClick={onClick}>{textContent}</TextStyle>;
}

export default ClickableText;

const TextStyle = styled.div`
  color: white;
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
