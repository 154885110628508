import React, { useState } from "react";
import { CommonResponse } from "../types/api";
import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../api/axios";
import { useNavigate } from "react-router-dom";

function useLogin() {
  const [error, setError] = useState<AxiosError | null>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const postData = async (email: string, password: string) => {
    setLoading(true);
    try {
      const response: AxiosResponse<CommonResponse<{ accessToken: string }>> =
        await axiosInstance.post("/user/login", {
          email,
          password,
        });
      if (response.data.data) {
        localStorage.setItem("mikuro_token", response.data.data.accessToken);
        navigate("/dashboard");
      } else {
        alert("Failed to Login. Please try again later");
      }
    } catch (e: any) {
      setError(e);
      alert("Failed to Login. Please try again later");
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, postData };
}

export default useLogin;
