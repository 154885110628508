import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import DashBoardPage from "../pages/DashBoardPage";
import IssuesPage from "../pages/IssuesPage";
import TargetsPage from "../pages/TargetsPage";
import ScanPage from "../pages/ScanPage";
import ReportsPage from "../pages/ReportsPage";
import { SidebarLayout } from "./SidebarLayout";
import NotfoundPage from "../pages/NotfoundPage";
import SelectedReportPage from "../pages/SelectedReportPage";
export default function MainRouter() {
  return (
    <>
      <Routes>
        <Route element={<SidebarLayout />}>
          <Route path="/dashboard" element={<DashBoardPage />} />
          <Route path="/assets" element={<TargetsPage />} />
          <Route path="/scans" element={<ScanPage />} />
          {/* <Route path="/issues" element={<IssuesPage />} /> */}
          <Route path="/reports" element={<ReportsPage />} />
          <Route path="/reports/:id" element={<SelectedReportPage />} />
        </Route>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<NotfoundPage />} />
      </Routes>
    </>
  );
}
