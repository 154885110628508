import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { AssetsSchema } from "../types/assets";
import { axiosInstance } from "../api/axios";
import { AssetsResponse, CommonResponse } from "../types/api";

type AssetAddResponse = CommonResponse<null>;
function useAssets() {
  const [data, setData] = useState<AssetsSchema>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(false);
  // const [assets, setAssets] = useState<>();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response: AxiosResponse<AssetsResponse> = await axiosInstance.get(
        "/asset"
      );
      if (response.status === 200 && response.data.ok) {
        setData(response.data.data);
      }
    } catch (e: any) {
      setError(e);
    }

    setLoading(false);
  };

  const fetchAddAsset = async (value: string) => {
    setLoading(true);
    try {
      const response: AxiosResponse<AssetAddResponse> =
        await axiosInstance.post("/asset", {
          value,
        });
      if (response.status === 200) {
        fetchData();
      }
    } catch (e: any) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return { data, error, loading, fetchAddAsset };
}

export default useAssets;
