import TargetManagerItem from "./TargetManagerItem";
import TargetSorter from "./TargetSorter";
import { ContainerStyle } from "../common/DefaultContainer";
import styled from "styled-components";
import { AssetsSchema } from "../../types/assets";
import { NoScanStyle } from "../Scans/ScanManager";

interface TargetManagerProps {
  assets: AssetsSchema;
  handleAssetScan: any;
}
export default function TargetManager({
  assets,
  handleAssetScan,
}: TargetManagerProps) {
  const length = assets.length;
  return (
    <ContainerStyle>
      <TargetHeaderStyle>
        <div>{length} assets</div>
      </TargetHeaderStyle>
      <TargetBodyStyle>
        {/* <TargetSorter /> */}
        <TargetListStyle>
          {assets.length ? (
            assets.map((asset) => (
              <TargetManagerItem
                key={asset.id}
                asset={asset}
                onClick={handleAssetScan}
              />
            ))
          ) : (
            <NoScanStyle>No assets were found</NoScanStyle>
          )}
        </TargetListStyle>
      </TargetBodyStyle>
    </ContainerStyle>
  );
}

const TargetHeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.padding.base};
  border-bottom: solid 1px ${({ theme }) => theme.lightTheme.border};
  font-size: 1.2rem;
  font-weight: 500;
`;

const TargetBodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow-y: auto;
`;

const TargetListStyle = styled.ol`
  width: 100%;
  overflow-y: auto;
`;
