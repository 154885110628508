import DashboardBlock from "../common/DashboardBlock";
import styled from "styled-components";

type PortsBlockProps = {
  ports: {
    assetCnt: number;
    max: number;
    min: number;
    mean: number;
  };
};
export default function PortsBlock({ ports }: PortsBlockProps) {
  const { assetCnt, max, min, mean } = ports;
  return (
    <DashboardBlock width="auto">
      <HeaderTitleStyle>Ports per Asset</HeaderTitleStyle>
      <div>
        <BoldStyle>{assetCnt}</BoldStyle> Assets
      </div>
      <div>
        <BoldStyle>{max}</BoldStyle> max ports
      </div>
      <div>
        <BoldStyle>{min}</BoldStyle> min ports
      </div>
      <div>
        <BoldStyle>{mean}</BoldStyle> avg ports
      </div>
    </DashboardBlock>
  );
}

const HeaderTitleStyle = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.base};
  padding-bottom: 0.5rem;
`;
const BoldStyle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
`;
