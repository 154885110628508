import { useState, useEffect } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
  DashBoardSchema,
  DashboardResponse,
  TargetReportResponse,
} from "../types/api";
import { axiosInstance } from "../api/axios";
import { IssueDetail } from "../types/dashboard";

const useDashboard = () => {
  const [data, setData] = useState<DashBoardSchema>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(false);
  const [targetIssues, setTargetIssues] = useState<IssueDetail[]>();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response: AxiosResponse<DashboardResponse> =
        await axiosInstance.get("/asset/main");
      if (response.status === 200 && response.data.ok) {
        setData(response.data.data);
      }
    } catch (e: any) {
      setError(e);
    }

    setLoading(false);
  };

  const fetchTargetIssues = async (id: number) => {
    setLoading(true);
    try {
      const response: AxiosResponse<TargetReportResponse> =
        await axiosInstance.get(`/asset/main/${id}`);
      if (response.status === 200 && response.data.ok) {
        setTargetIssues(response.data.data);
      }
    } catch (e: any) {
      setError(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    data,
    loading,
    error,
    targetIssues,
    setTargetIssues,
    fetchTargetIssues,
  };
};

export default useDashboard;
