import styled from "styled-components";
import Loading from "./Loading";

type DefaultButtonProps = {
  width?: string;
  height?: string;
  onClick: any;
  isLoading?: boolean;
  children: React.ReactNode;
};

export default function DefaultButton({
  width = "140px",
  height = "45px",
  onClick = () => {},
  children,
  isLoading = false,
}: DefaultButtonProps) {
  return (
    <ButtonStyle
      $width={width}
      $height={height}
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? <Loading /> : children}
    </ButtonStyle>
  );
}

const ButtonStyle = styled.button<{
  $width: string;
  $height: string;
}>`
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  background-color: ${({ theme }) => theme.lightTheme.focus};
  color: ${({ theme }) => theme.lightTheme.fontPrimary};
  border-radius: ${({ theme }) => theme.rounded.xs};
  border-style: none;

  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: 600;

  cursor: pointer;

  &:hover {
    opacity: 0.7;
    /* box-shadow: 0 0 3px 3px ${({ theme }) => theme.lightTheme.boldBorder}; */
  }
`;
