import React from "react";
import { ContainerStyle } from "../common/DefaultContainer";
import { ReportsDetail, ReportsSchema } from "../../types/api";
import styled from "styled-components";
import { NoScanStyle } from "../Scans/ScanManager";

type ReportListProps = {
  reports: ReportsSchema;
  selectedReport: ReportsDetail;
  onClick: any;
};
function ReportsList({ reports, selectedReport, onClick }: ReportListProps) {
  return (
    <ContainerStyle>
      <IssueHeaderStyle></IssueHeaderStyle>
      <IssueBodyStyle>
        <IssueListStyle>
          {reports.length ? (
            reports.map((report) => {
              const {
                id,
                time,
                issueList,
                finished,
                assetValue,
                critical,
                high,
                medium,
                low,
                port,
                assetId,
              } = report;
              return (
                <IssueItemStyle
                  key={id}
                  onClick={() => onClick(id)}
                  $focus={id === selectedReport.id}
                >
                  <div className="r g">
                    <div className="title">Report for {assetValue}</div>
                    <div className="time">{time}</div>
                    <style>
                      {`
                      .title {
                        font-size: 1.5rem;
                        font-weight: bold;
                        padding-bottom: 0.5rem;
                      }
                      .time {
                        font-size: 1rem;
                        color: #999;
                      }
                    `}
                    </style>
                  </div>
                </IssueItemStyle>
              );
            })
          ) : (
            <NoScanStyle>No Reports were found</NoScanStyle>
          )}
        </IssueListStyle>
      </IssueBodyStyle>
    </ContainerStyle>
  );
}

export default ReportsList;

const IssueHeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.padding.base};
  border-bottom: solid 1px ${({ theme }) => theme.lightTheme.border};
`;
const IssueBodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.lightTheme.secondary};
`;

const IssueListStyle = styled.ol`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  /* width: 100%; */
  overflow-y: auto;
  /* overflow-x: hidden; */
`;
const IssueItemStyle = styled.li<{ $focus: boolean }>`
  padding: ${({ theme }) => theme.padding.xs};
  list-style: none;
  /* width: 100%; */
  /* padding: ${({ theme }) => theme.padding.sm}; */
  border-radius: ${({ theme }) => theme.rounded.sm};
  border: 1px solid ${({ theme }) => theme.lightTheme.border};
  background-color: ${({ theme, $focus }) =>
    $focus ? theme.lightTheme.focus : theme.lightTheme.primary};

  cursor: pointer;
`;
