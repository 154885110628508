import styled from "styled-components";
import Loading from "./Loading";

export default function LoadingSection() {
  return (
    <LoadingSectionStyle>
      <Loading width="5rem" thickness="0.5rem" />
    </LoadingSectionStyle>
  );
}
const LoadingSectionStyle = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
`;
